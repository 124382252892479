<template>
  <v-app class="cpContainer">
    <main>
      <v-sheet id="pretrade-depth" ref="appContainer">
        <v-container>
          <v-row class="cpRow">
            <v-col class="cpTitle">
              <span>Latest 24 hours available up until </span><span id="pretrade-depth-latest-date">{{ date }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-container class="overflow-y-auto">
              <tree-view :item="items" @tree-change="publishHeight"></tree-view>
            </v-container>
          </v-row>
        </v-container>
      </v-sheet>
    </main>
  </v-app>
</template>

<script>
import $ from 'jquery';
import TreeView from '../components/TreeView';
import Vue from 'vue';

export default {
  name: 'App',
  data() {
    return {
      latest: '',
      items: {}
    };
  },
  mounted() {
    this.getIndex();
    this.publishHeight();
  },
  computed: {
    date() {
      if (this.latest.length === 0) {
        return '';
      }
      const start = this.latest.lastIndexOf('/');
      const latestUploadDate = this.latest.substring(start + 14, start + 24);
      const latestUploadTime = this.latest.substring(start + 25, start + 30).replace('-', ':');
      return `${latestUploadDate} ${latestUploadTime} UTC`;
    }
  },

  methods: {

    getIndex() {
      let allItems = {id: 0, name: null, children: {}};
      const scopes = [
        {name: 'London', url: '/data/london/index.json'},
        {name: 'London Digital', url: '/data/digital/index.json'}
      ];

      scopes.forEach(scope => this.updateIndexByScope(scope, allItems));
    },

    updateIndexByScope(scope, allItems) {
      let cnt = 0;
      allItems.children[scope.name] = {id: cnt, name: scope.name, tag: scope.name, children: {}};
      let items = allItems.children[scope.name];

      $.ajax(scope.url, {type: 'GET', quiet: true, dataType: 'json', cache: false}).done(response => {

        this.latest = response.latest;

        response.files.forEach(file => {
          const idx = file.lastIndexOf('/') + 1;

          let filename = file.substring(idx);
          let path = `/${file}`;
          let date = filename.substring(13, 23);
          let hour = filename.substring(24, 26);
          let minute = filename.substring(27, 29);
          let hour_tag = `${hour}:00 - ${hour}:59`;

          if (!(date in items.children)) {
            cnt += 1;
            items.children[date] = {id: cnt, name: date, tag: date.replace('-', ''), children: {}};
          }

          if (!(hour_tag in items.children[date].children)) {
            cnt += 1;
            items
                .children[date]
                .children[hour_tag] = {
              id: cnt,
              name: hour_tag,
              tag: filename.substring(13, 26).replace('-', ''),
              children: []
            };
          }

          cnt += 1;
          items
              .children[date]
              .children[hour_tag]
              .children.push({
                               id: cnt,
                               name: filename,
                               tag: filename.substring(13, 28).replace('-', ''),
                               path: path,
                               minute: Number(minute),
                               children: []
                             });
        });

        Object.keys(items.children).forEach(k => {
          const hourKeys = items.children[k].children;
          Object.keys(hourKeys).forEach(k2 => {
            hourKeys[k2].children.sort((a, b) => a.minute - b.minute);
          });
        });

        this.items = allItems;
        this.publishHeight();
      });
    },

    publishHeight() {
      if (this.standaloneWindow()) {
        return;
      }
      const vm = this;
      Vue.nextTick(function() {
        if (parent.postMessage) {
          let container = vm.$refs.appContainer;
          if (undefined === container.getBoundingClientRect) {
            container = container.$el;
          }
          if (container.getBoundingClientRect) {
            const containerSize = container.getBoundingClientRect();
            parent.postMessage({height: containerSize.height}, '*');
          }
        }
      });
    },
    standaloneWindow() {
      try {
        return window.self === window.top;
      } catch (e) {
        return false;
      }
    }
  },
  components: {
    TreeView
  }
};
</script>

<style>
@import '../assets/css/base.css';
</style>
