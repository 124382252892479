<template>
  <v-app class="cpContainer">
    <main>
      <v-sheet id="pretrade-depth" ref="appContainer">
        <v-container>
          <v-row class="cpRow">
            <p>The selected download is no longer available, files are available online for the most recent 24 hours of trading.</p>
          </v-row>
        </v-container>
      </v-sheet>
    </main>
  </v-app>
</template>

<script>

export default {
  name: 'NotFound'
};
</script>

<style>
@import '../assets/css/base.css';
</style>
