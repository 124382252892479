<template>
    <div>
        <div>
            <div v-if="item.name !== null">
                <v-btn v-if="!isLeaf" icon @click="expand" class="tree-view-item-expand-btn">
                    <v-icon>{{expandIcon}}</v-icon>
                </v-btn>
                <span class="tree-view-item" @click="expand">{{item.name}}</span>
                <v-btn v-if="isLeaf" icon :href="item.path" :id="item.name" class="tree-view-item-download">
                    <v-icon>get_app</v-icon>
                </v-btn>
            </div>

            <div v-if="showChildren" class="pl-12">
                <div v-for="child in getChildren(item)" :key="child.id">
                    <tree-view :item="child" @tree-change="childTreeChange"></tree-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TreeView',
        props: ['item'],
        data() {
            return {
                expanded: false
            };
        },
        computed: {
            expandIcon() {
                return this.expanded ? 'expand_more' : 'chevron_right';
            },
            showChildren() {
                return this.expanded || this.item.name === null;
            },
            isLeaf() {
                return this.item.children === undefined || this.item.children === null || this.item.children.length === 0;
            }
        },
        methods: {
            getChildren(item) {
                if (item.children === null || item.children === undefined) {
                    return [];
                }
                let children = item.children;
                if (Array.isArray(children)) {
                    return children;
                }
                return Object.keys(children).sort().map(c => children[c]);
            },
            expand() {
                this.expanded = !this.expanded;
                this.$emit("tree-change")
            },
            childTreeChange() {
                this.$emit("tree-change")
            }
        }
    };
</script>
