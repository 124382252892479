import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont';
import 'typeface-roboto';

import App from './App';
import NotFound from './NotFound';


Vue.config.productionTip = false;
Vue.use(Vuetify);

/* eslint-disable no-new */
new Vue({
            el: '#app',
            vuetify: new Vuetify(),
            components: {},
            render: h => h(App)
        });

/* eslint-disable no-new */
new Vue({
            el: '#app-not-found',
            vuetify: new Vuetify(),
            components: {},
            render: h => h(NotFound)
        });

